import logo1Win from "./logo1Win.svg";
import logoUpX from "./logoUpX.svg";
import logoLuckyStar from "./logoLuckyStar.svg";

import './Preloader.css';
import ProgressBar from "./ProgressBar";
import {useParams} from "react-router";
import React, {RefObject, useState, Component} from "react";

declare global {
    interface Window {
        HideLogo: any;
    }
}

function LogoUpX() {
    return (
        <div id="logo-parent">
            <div id="logo-wrapper">
                <img id="logo" src={logoUpX} alt={"logo"}/>
            </div>
        </div>
    )
}

function LogoLuckyStar() {
    return (
        <div id="logo-parent">
            <div id="logo-wrapper">
                <img id="logo" src={logoLuckyStar} alt={"logo"}/>
            </div>
        </div>
    )
}

function Logo1Win() {
    return (
        <div id="logo-parent">
            <div id="logo-wrapper">
                <div id="coin"></div>
                <img id="logo" src={logo1Win} alt={"logo"}/>
            </div>
        </div>
    )
}

function hide1WinLogo() {
    const location: Location = window.location;

    if (location) {
        let exitUrlParam: string | null = new URL(location.toString()).searchParams.get('exitUrl');

        if (exitUrlParam) {
            let exitUrl: URL | null = null;

            try {
                exitUrl = new URL( exitUrlParam );
            } catch (e) { }

            if ( !exitUrl )
                try {
                    exitUrl = new URL(decodeURIComponent(exitUrlParam));
                }  catch (e) { }

            if ( !exitUrl )
                try {
                    return /^.*\.mx$/ig.test(exitUrlParam)
                }  catch (e) { }

            if (exitUrl?.hostname.includes(".mx")) {
                return true;
            }
        }
    }

    return false;
}

function Preloader() {
    var {gameProvider} = useParams()
    const [isActive, setIsActive] = useState(true);

    window.HideLogo = function () {
        console.log("try hide logo react");
        setIsActive(false);
    }

    if (isActive && gameProvider === "up-x") {
        return (
            <div id="logo-container">
                <LogoUpX/>
                <ProgressBar/>
            </div>
        )
    } else if (isActive && gameProvider === "1win" && !hide1WinLogo()) {
        return (
            <div id="logo-container">
                <Logo1Win/>
                <ProgressBar/>
            </div>
        )
    } else if (isActive && gameProvider === "lucky-star") {
        return (
            <div id="logo-container">
                <LogoLuckyStar/>
                <ProgressBar/>
            </div>
        )
    } else if (isActive) {
        return (
            <div id="logo-container">
                <ProgressBar/>
            </div>
        )
    }
    return null;
}

export default Preloader;
